import '../styles/Home3.css';
import styles from '../styles/landings/Landing.module.css';
import { AimOutlined, MailOutlined } from '@ant-design/icons';
import { Image, Col, Row, Layout, Typography, Switch, Radio, Space } from 'antd';
import { useSpring, animated, useSpringRef, useChain, useTrail } from '@react-spring/web'
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { SignupButton } from "../components/buttons/signup-button";
import { isMobile } from '../utils/utils';
import { COLOR_PRIMARY, tradingProfileOptions } from '../JTIConst'
import { TagAITagging } from '../components/Tags';
import { IconSignalBuyL1, IconSignalSellL1 } from '../components/Icons';
import Arrival1 from './landings/pages/Arrival1';


// SHARED VARIABLES //
const { Title, Text } = Typography
const IS_MOBILE = isMobile()


const Home3 = () => {

    // OBSERVER //

    const [refEnrollement, inViewEnrollement] = useInView({
        triggerOnce: true,
        threshold: IS_MOBILE ? 0.2 : 0.4,
    })

    const [refP1, inViewP1] = useInView({
        triggerOnce: false, // Only trigger once when entering viewport

        // Percentage of the HTML element visibility to trigger the animation
        threshold: IS_MOBILE ? 0.3 : 0.5,
    })

    const [refP2, inViewP2] = useInView({
        triggerOnce: false,
        threshold: IS_MOBILE ? 0.3 : 0.5,
    })

    const [refFinancialCharts, inViewFinancialCharts] = useInView({
        triggerOnce: false,
        threshold: IS_MOBILE ? 0.3 : 0.40,
    })

    const [refScreener, inViewScreener] = useInView({
        triggerOnce: false,
        threshold: IS_MOBILE ? 0.3 : 0.50,
    })

    const [refWatchlist, inViewWatchlist] = useInView({
        triggerOnce: false,
        threshold: IS_MOBILE ? 0.3 : 0.40,
    })

    const [refP3, inViewP3] = useInView({
        triggerOnce: false,
        threshold: IS_MOBILE ? 0.3 : 0.6,
    })

    const [refP4, inViewP4] = useInView({
        triggerOnce: false,
        threshold: IS_MOBILE ? 0.3 : 0.54,
    })

    // SPRING EFFECTS //

    const springCandleChart = useSpring({
        config: { duration: 1000 },
        from: { opacity: inViewP1 ? 0 : 1, },
        to: {
            opacity: inViewP1 ? 1 : 0, // Set opacity based on visibility
            //transform: inView ? 'translateX(0)' : 'translateX(100px)', // Set transform based on visibility
        },
    })

    // Enrollement
    const springRefEnrollementTitle = useSpringRef()
    const springEnrollementTitle = useSpring({
        ref: springRefEnrollementTitle,
        config: { duration: 1000 },
        from: { opacity: inViewEnrollement ? 0 : 0, },
        to: {
            opacity: inViewEnrollement ? 1 : 0,
        },
    })

    const springRefEnrollement = useSpringRef()
    const trailEnrollement = useTrail(tradingProfileOptions.length, {
        ref: springRefEnrollement,
        config: { duration: 400 },
        from: {
            opacity: inViewEnrollement ? 0 : 1,
        },
        to: {
            opacity: inViewEnrollement ? 1 : 0,
        },
    });
    useChain([springRefEnrollementTitle, springRefEnrollement])

    // Financial Charts
    const springFinancialCharts = useSpring({
        config: { duration: 1000 },
        from: { opacity: inViewFinancialCharts ? 0 : 1, },
        to: {
            opacity: inViewFinancialCharts ? 1 : 0,
        },
    })

    // Screener
    const springScreener = useSpring({
        config: { duration: 1000 },
        from: { opacity: inViewScreener ? 0 : 1, },
        to: {
            opacity: inViewScreener ? 1 : 0,
        },
    })

    // Watchlist
    const springWatchlist = useSpring({
        config: { duration: 1000 },
        from: { opacity: inViewWatchlist ? 0 : 1, },
        to: {
            opacity: inViewWatchlist ? 1 : 0,
        },
    })

    // Financial Graph
    const springFinancial = useSpring({
        config: { duration: 1000 },
        from: { opacity: inViewP2 ? 0 : 1, },
        to: {
            opacity: inViewP2 ? 1 : 0,
        },
    })

    // Index View
    const springIdxView = useSpring({
        config: { duration: 1000 },
        from: { opacity: inViewP3 ? 0 : 1, },
        to: {
            opacity: inViewP3 ? 1 : 0,
        },
    })

    // Last
    const springRefLove = useSpringRef()
    const springLove = useSpring({
        ref: springRefLove,
        config: { duration: 1000 },
        from: { opacity: inViewP4 ? 0 : 1, },
        to: {
            opacity: inViewP4 ? 1 : 0,
        },
    })
    const springRefGetStarted = useSpringRef()
    const springGetStarted = useSpring({
        ref: springRefGetStarted,
        config: { duration: 900 },
        from: {
            opacity: inViewP4 ? 0 : 1,
            transform: 'translateX(-30)',
        },
        to: {
            opacity: inViewP4 ? 1 : 0,
            transform: inViewP4 ? 'translateX(0)' : 'translateX(-30px)'
        },
    })
    useChain([springRefLove, springRefGetStarted])


    const financialGraphVideoRef = useRef(null);
    useEffect(() => {
        const video = financialGraphVideoRef.current;
        video.play()

        let interval = null
        if (inViewP2) {
            interval = setInterval(() => {
                video.currentTime = 0   // Restart the video from the beginning
                video.play();           // Start playing the video
            }, 5000)                    // Repeat every 5 seconds
        }

        return () => {
            clearInterval(interval) // Clean up the interval when the component unmounts > Can cause memory leak
        };
    }, [inViewP2])


    // Style
    const gutter = { xs: 0, sm: 0, md: 30, lg: 90 }

    return (
        <Layout>
            <div className={styles['landing-content']}>
                <Arrival1 showSignupButton={false} />
            </div>

            <div className="pageEnrollement" ref={refEnrollement}>
                <div className="landing-content">
                    <Row justify="space-around">
                        <animated.div className='enrollement-title' style={springEnrollementTitle}>Effortless Trading Strategy <span style={{ color: COLOR_PRIMARY }}>In Few Steps</span></animated.div>
                    </Row>
                    <Row gutter={{ xs: 0, sm: 90 }}>
                        <Col className="text" xs={24} md={8}>
                            <animated.div style={trailEnrollement[0]}>
                                <Title level={2} className='page-title'>
                                    <div className='title-number'>1.</div>Choose your trading profile
                                </Title>
                                <Text className='page-description' style={{ paddingLeft: '4.5em' }}>
                                    <Radio.Group optionType="button" buttonStyle="solid" value={2}>
                                        <Space direction="vertical" size='large'>
                                            <Radio value={1} className='radioButton'>{tradingProfileOptions[0].label}</Radio>
                                            <Radio value={2} className='radioButton'>{tradingProfileOptions[1].label}</Radio>
                                            <Radio value={3} className='radioButton'>{tradingProfileOptions[2].label}</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Text>
                            </animated.div>
                        </Col>
                        <Col className="text" xs={24} md={8}>
                            <animated.div style={trailEnrollement[1]}>
                                <Title level={2} className='page-title'>
                                    <div className='title-number'>2.</div>Add symbols to your watchlist
                                </Title>
                                <Text className='page-description'>
                                    Use the <b>Screener</b> to find the best market opportunities based on our <b>AI Signals</b> &nbsp;<IconSignalBuyL1 /> <IconSignalSellL1 />and <TagAITagging style={{ marginRight: '0' }} />, then add them to your <b>Watchlist</b>.
                                    <div align='center'><AimOutlined style={{ fontSize: '3em', marginTop: '0.4em' }} /></div>
                                </Text>
                            </animated.div>
                        </Col>
                        <Col className="text" xs={24} md={8}>
                            <animated.div style={trailEnrollement[2]}>
                                <Title level={2} className='page-title'>
                                    <div className='title-number'>3.</div>Let the AI model work for you
                                </Title>
                                <Text className='page-description'>
                                    The <b>AI Model</b> analyzes all items in your <b>watchlist daily</b>,
                                    sending you notifications on <b>the best Buy and Sell Momentum</b> aligned with your <b>Trading Profile</b>.
                                    <div align='center'><MailOutlined style={{ fontSize: '3em', marginTop: '0.4em' }} /></div>
                                </Text>
                            </animated.div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="page1" ref={refP1}>
                <animated.div className="landing-content" style={{ ...springCandleChart, }}>
                    <Row gutter={gutter}>
                        <Col className="text" xs={24} md={8}>
                            <Title level={1} className='page-title'>Candlestick Chart With AI Insights</Title>
                            <Text className='page-description'>
                                Discover the power of our cutting-edge <b>Multi-Model AI</b>.
                                It intelligently analyzes market charts, pinpointing<br />
                                <IconSignalBuyL1 /><b>Buying</b> and &nbsp;<IconSignalSellL1 /><b>Selling Momentum</b> right on the <b>interactive chart</b>.<br />
                                Gain an unrivaled perspective on the lifecycle of your assets and elevate your trading game with our <b>AI-driven insights!</b>
                            </Text>
                            <div><SignupButton className="landing-signup" type='primary' label='Start Analyzing Free' /></div>
                        </Col>
                        <Col xs={24} md={16}>
                            <div className='img-background-w'>
                                <Image src='/landing_candlestick_chart.png' alt='Candlestick Chart AI Signals' preview={false} />
                            </div>
                        </Col>
                    </Row>
                </animated.div>
            </div>

            <div className="page-financial-charts" ref={refFinancialCharts}>
                <animated.div className="landing-content" style={{ ...springFinancialCharts, }}>
                    <Row gutter={gutter}>
                        <Col xs={24} md={16}>
                            <div className='img-background-g'>
                                <Image src='/landing_financial_charts.png' alt='Financials Charts' preview={false} />
                            </div>
                        </Col>
                        <Col className="text" xs={24} md={8}>
                            <Title level={1} className='page-title'>Beautiful Financial Charts</Title>
                            <Text className='page-description'>
                                Explore visually <b>stunning charts</b> that illuminate company <b>financial statements</b> and <b>fundamentals</b>, 
                                making it easier than ever to spot <b>key metrics</b> and <b>trends</b>. 
                                With our app, you'll make <b>informed investment decisions</b> with <b>confidence</b> and data visualization that makes sense. 
                            </Text>
                            <div><SignupButton className="landing-signup" type='primary' label='Explore Financial Charts Free' /></div>
                        </Col>
                    </Row>
                </animated.div>
            </div>

            <div className="pageScreener" style={{ borderBottom: '1px solid rgba(232, 232, 232, 0.9)' }} ref={refScreener}>
                <animated.div className="landing-content" style={{ ...springScreener }}>
                    <Row gutter={gutter}>
                        <Col className="text" xs={24} md={8}>
                            <Title level={1} className='page-title'>AI Screener Filters</Title>
                            <Text className='page-description'>
                                Find the <b>best</b> market opportunities in just few steps with our <b>AI Signals</b> &nbsp;<IconSignalBuyL1 /> <IconSignalSellL1 />and <TagAITagging style={{ marginRight: '0' }} />.<br />
                                Our AI Model continuously analyzes all assets, so you don’t have to.
                            </Text>
                            <div><SignupButton className="landing-signup" type='primary' label='Explore AI Screener Free' /></div>
                        </Col>
                        <Col xs={24} md={16}>
                            <div className='img-background-w'>
                                <Image src='/landing_screener.png' alt='Screener' preview={false} style={{ padding: '0.2em'}} />
                            </div>
                        </Col>
                    </Row>
                </animated.div>
            </div>

            <div className="pageWatchlist" ref={refWatchlist}>
                <animated.div className="landing-content" style={{ ...springWatchlist, }}>
                    <Row gutter={gutter}>
                        <Col xs={24} md={16}>
                            <div className='img-background-g'>
                                <Image src='/landing_watchlist.png' alt='Watchlist' preview={false} />
                            </div>
                        </Col>
                        <Col className="text" xs={24} md={8}>
                            <Title level={1} className='page-title'>Instant Watchlist Insights</Title>
                            <Text className='page-description'>
                                The <b>AI Model</b> highlight symbols with interesting<br />
                                &nbsp;<IconSignalBuyL1 /><b>Buying</b> and &nbsp;<IconSignalSellL1 /><b>Selling Momentum</b>.
                                enabling you to identify assets of interest without wasting time.<br />
                                Activate <b>Alerts</b> <Switch checked={true} size='small' style={{ marginBottom: '0.2em' }} /> for any symbol to be notified as soon as a signal is triggered.
                            </Text>
                            <div><SignupButton className="landing-signup" type='primary' label='Create Your Watchlist Free' /></div>
                        </Col>
                    </Row>
                </animated.div>
            </div>

            <div className="page2" ref={refP2}>
                <animated.div className="landing-content" style={{ ...springFinancial, }}>
                    <Row gutter={gutter}>
                        <Col className="text" sm={24} md={9}>
                            <Title level={1} className='page-title'>The Financial Dependency Graph</Title>
                            <Text className='page-description'>
                                Get a comprehensive view of the <b>interrelationships among financial companies</b>. 
                                Our Financial Dependency Graph provides a visual map of these connections, 
                                empowering you to <b>anticipate</b> potential ripple effects and make <b>proactive decisions</b>.
                            </Text>
                            <div><SignupButton className="landing-signup" type='primary' label='Access To Financial Graph Free' /></div>
                        </Col>
                        <Col sm={24} md={15}>
                            <div className='img-background-w'>
                                <video style={{ backgroundColor: '#fff', padding: '0.6em', borderRadius: '10px' }} ref={financialGraphVideoRef} src='/financial_graph.mp4' playsInline muted />
                            </div>
                        </Col>
                    </Row>
                </animated.div>
            </div>

            <div className="page3" ref={refP3}>
                <animated.div className="landing-content" style={{ ...springIdxView, }}>
                    <Row gutter={gutter}>
                        <Col xs={24} md={16}>
                            <div className='img-background-g'>
                                <Image src='/indexes_view.png' alt='Stock Index View' preview={false} />
                            </div>
                        </Col>
                        <Col className="text" xs={24} md={8}>
                            <Title level={1} className='page-title'>Market At a Glance</Title>
                            <Text className='page-description'>
                                Get a <b>panoramic overview</b> of the market with our Stock Index View. 
                                See the <b>prevailing trends</b> and <b>valuable insights</b> at a <b>single glance</b>, 
                                giving you the knowledge you need to stay <b>ahead</b>.
                            </Text>
                            <div><SignupButton className="landing-signup" type='primary' label='Explore Market At A Glance Free' /></div>
                        </Col>
                    </Row>
                </animated.div>
            </div>

            <div className="page4" ref={refP4}>
                <div className="landing-content">
                    <animated.div className="value-title" style={{ ...springLove, }}>
                        Trade Smarter, Not Harder...
                    </animated.div>
                    <animated.div style={{ ...springGetStarted, }}>
                        <SignupButton className="value-signup" label='Get Started For Free' />
                    </animated.div>
                </div>
            </div>
        </Layout>
    )
}

export default Home3;