import { callExternalApi } from "./external-api.service";


const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


export const getPostAll = async (accessToken) => {
	const config = {
		url: `${apiServerUrl}/api/community/post/all`,
		method: "GET",
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const updatePostStatus = async (accessToken, id, status) => {
	const config = {
		url: `${apiServerUrl}/api/community/post/${id}/status`,
		method: "PUT",
		data: status,
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const updateXPosting = async (accessToken, id, newValue) => {
	const config = {
		url: `${apiServerUrl}/api/community/post/${id}/x`,
		method: "PUT",
		data: newValue,
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const updateFacebookPosting = async (accessToken, id, newValue) => {
	const config = {
		url: `${apiServerUrl}/api/community/post/${id}/facebook`,
		method: "PUT",
		data: newValue,
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const updateInstagramPosting = async (accessToken, id, newValue) => {
	const config = {
		url: `${apiServerUrl}/api/community/post/${id}/instagram`,
		method: "PUT",
		data: newValue,
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const updateRedditPosting = async (accessToken, id, newValue) => {
	const config = {
		url: `${apiServerUrl}/api/community/post/${id}/reddit`,
		method: "PUT",
		data: newValue,
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const updateTiktokPosting = async (accessToken, id, newValue) => {
	const config = {
		url: `${apiServerUrl}/api/community/post/${id}/tiktok`,
		method: "PUT",
		data: newValue,
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const deletePost = async (accessToken, id) => {
	const config = {
		url: `${apiServerUrl}/api/community/post/${id}`,
		method: "DELETE",
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};