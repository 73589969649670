import styles from '../../styles/landings/Landing.module.css';
import { Layout } from 'antd';
import Arrival1 from './pages/Arrival1';


// SHARED VARIABLES //


const Landing = () => {

    return (
        <Layout>
            <div className={styles['landing-content']}>
                <Arrival1 />
            </div>
        </Layout>
    )
}

export default Landing;