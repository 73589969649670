import { Descriptions, Layout, Radio, Checkbox, Popconfirm, Button, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { IconLoading, IconWarn } from '../components/Icons';
import UserContext from '../components/UserContext';
import { getJTIParameters } from '../services/jti.service';
import { printUTCDate } from '../utils/utils';
import { tradingProfileOptions } from '../JTIConst'
import { cancel } from '../services/payment.service';
import PageTitle1 from '../components/PageTitle1';


const { Item, } = Descriptions;
const CheckboxGroup = Checkbox.Group;


const Account = () => {

    const { getAccessTokenSilently } = useAuth0();
    const { userContext, updateUserContext } = useContext(UserContext);
    const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false)
    const [selectedTradingProfile, setSelectedTradingProfile] = useState('')
    const [jtiParameters, setJTIParameters] = useState(null);


    const emailPrefOpts = [{ value: 'newsletters', label: 'Newsletters' }]
    const [checkedEmailPrefOpts, setCheckedEmailPrefOpts] = useState([])

    const onEmailPrefChange = (list) => {

        // Set all keys to false
        for (const key of Object.keys(userContext.emailPreferences)) {
            userContext.emailPreferences[key] = false
        }

        // Set user selected keys to true
        for (const key of list) {
            userContext.emailPreferences[key] = true
        }

        setCheckedEmailPrefOpts(list)
        updateUserContext(userContext)
    }

    const handleCancel = async () => {
        setCancelSubscriptionLoading(true)

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await cancel(accessToken)

        if (error)
            throw new Error('Error during payment processing')

        setCancelSubscriptionLoading(false)
        window.location.reload()
    }

    const updTradingProfile = (evt) => {
        const profile = evt.target.value
        setSelectedTradingProfile(profile)
        userContext.tradingProfile = profile
        updateUserContext(userContext)
    }

    useEffect(() => {

        if (userContext != null) {
            setSelectedTradingProfile(userContext.tradingProfile)

            // Set user email peferences
            if (userContext.emailPreferences != null) {

                let userEmailPref = []
                for (const [key, value] of Object.entries(userContext.emailPreferences)) {
                    if (value)
                        userEmailPref.push(key)
                }

                setCheckedEmailPrefOpts(userEmailPref)
            }
        }

        const fetchData = async () => {
            const { data, error } = await getJTIParameters()

            setJTIParameters(data)
        }
        fetchData()
    }, [userContext])

    // userContext needs to be setted otherwise error
    if (userContext == null || jtiParameters == null) {
        return (
            <Layout className="site-layout-content">
                <IconLoading />
            </Layout>)
    }

    return (
        <Layout className='site-layout-content-fixed'>
            <PageTitle1> • Account</PageTitle1>

            <Descriptions column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }} style={{ marginBottom: '1em' }} labelStyle={{ color: 'black' }} bordered>
                <Item label='Email' width={100}>{userContext.email}</Item>
                <Item label='Creation Date'>{printUTCDate(userContext.created)}</Item>
                <Item label='Subscription Plan'>{userContext.accountType}</Item>
                <Item label='Subscription Ref.'>{userContext.subscriptionId}</Item>
                <Item label='Subscription Start'>{printUTCDate(userContext.subscriptionStart)}</Item>
                <Item label='Subscription End'>
                    {userContext.subscriptionEnd !== null && <><IconWarn />&nbsp; Your subscription will end on </>}
                    {printUTCDate(userContext.subscriptionEnd)}
                </Item>
                <Item label='Watchlist Number Of Items'>{userContext.watchlist.length}/{jtiParameters.accountTypes[userContext.accountType].watchlistLimit}</Item>
                <Item label='Trading Profile'>
                    <Radio.Group optionType="button" buttonStyle="solid"
                        options={tradingProfileOptions}
                        onChange={updTradingProfile}
                        value={selectedTradingProfile}
                    />
                </Item>
                <Item label='Chart Mode'>{userContext.chartMode}</Item>
                <Item label='Email Preferences'>
                    <CheckboxGroup options={emailPrefOpts} value={checkedEmailPrefOpts} onChange={onEmailPrefChange} />
                </Item>
            </Descriptions>

            <div style={{ marginTop: '1em', textAlign: 'right' }}>
                <Space>
                    {
                        // MARK BIZR : Show "Cancel Subscription" button
                        userContext.subscriptionEnd === null && userContext.subscriptionId !== null && (userContext.accountType === 'MEMBER' || userContext.accountType === 'PRO') &&
                        <Popconfirm title="Are you sure you want to disable all features included in your plan?" onConfirm={() => handleCancel()}>
                            <Button type="primary" size='middle' loading={cancelSubscriptionLoading}>Cancel Subscription</Button>
                        </Popconfirm>
                    }
                </Space>
            </div>
        </Layout>
    )
}

export default Account;