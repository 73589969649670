import { Descriptions, Space, Flex, Divider, Card } from 'antd';
import { IconLoading, IconSignalBuyL1, IconSignalBuyL2, IconSignalSellL1, IconSignalSellL2, IconSignalOff, IconInfoExDivDate, IconInfoDivYield, IconInfoShortRatio, IconInfoWinLose, IconInfoAVGROI, IconInfoMedianSwing } from './Icons';
import React, { useEffect } from 'react';
import { IconInfoActiveBuySignal, IconInfoActiveSellSignal, IconWarnShortRatio } from './Icons';
import { isMobile, displayData, formatNumberToB, formatPourcent, getAITag } from '../utils/utils'
import { TagClassResilient, TagClassYoung } from './Tags';

const { Item, } = Descriptions;


// PARAMETERS //

const styleItalic = { fontSize: 'smaller' }


const TickerInfo = (props) => {

    // Let Tour run after the component is loaded, otherwise tour won't work
    useEffect(() => {
        props.setTickerInfoIsLoaded(true)
    })

    if (props.tkrInfos === null || props.isLoading) {
        return (
            <div style={{ height: '20em', alignItems: 'center', display: 'flex' }}>
                <IconLoading />
            </div>)
    }

    const tkrInfos = props.tkrInfos
    // console.log(JSON.stringify(tkrInfos))

    // Define interval
    var efficiency = tkrInfos.oneDay
    if (props.interval == 'hourly') {
        efficiency = tkrInfos.oneHour
    }

    // Define tags
    const tagResilient = tkrInfos.tags?.resilient ? <TagClassResilient /> : ''
    const tagYoung = tkrInfos.tags?.young ? <TagClassYoung /> : ''
    const modelEfficiencyTag = getAITag(efficiency.total_win_percent)

    return (
        <Card className='card'>
            <div ref={props.tourRefs[2]}>
                {/* Default is 3 */}
                <Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 2 }} size={isMobile() ? 'small' : 'default'}>
                    <Item label="CEO" span={1}>{tkrInfos.ceoName}</Item>
                    <Item label="Tags" span={2}><Flex gap="1em 0" wrap>{modelEfficiencyTag}{tagResilient}{tagYoung}</Flex></Item>
                    <Item label="Industry">{tkrInfos.industry}</Item>
                    <Item label="Exchange">{tkrInfos.exchange}</Item>
                    <Item label="Market Capitalization">{formatNumberToB(tkrInfos.marketCap)}</Item>
                    <Item label={<>Dividends Yield<IconInfoDivYield /></>}>{displayData(tkrInfos.dividendYield) === '-' ? '-' : formatPourcent(tkrInfos.dividendYield)}</Item>
                    <Item label={<>Ex-Dividend Date<IconInfoExDivDate /></>}>{displayData(tkrInfos.exDividendDate)}</Item>
                    <Item label={<>Short Ratio<IconInfoShortRatio /></>}>
                        {displayData(tkrInfos.shortRatio) === '-' ? '-' :
                            tkrInfos.shortRatio < 4 ? (
                                <>{tkrInfos.shortRatio}%</>
                            ) : (
                                // lineHeight: '0.8em' > Hack to make the icon sup working
                                <span style={{ lineHeight: '0.8em' }}>{tkrInfos.shortRatio}%<IconWarnShortRatio /></span>
                            )}
                    </Item>
                </Descriptions>
            </div>

            <Divider orientation="left" className='card-divider'>AI Model Efficiency</Divider>

            <div ref={props.tourRefs[3]}>
                <Descriptions column={{ xs: 1, sm: 1, md: 3, lg: 3 }} size={isMobile() ? 'small' : 'default'}>
                    <Item label={<>Win Rate&nbsp;<i style={styleItalic}>(Win/Loss)</i><IconInfoWinLose /></>}>
                        <span>
                            <b>{efficiency.total_win_percent.toFixed(2)}%</b> <i style={styleItalic}>({efficiency.total_win_trade}/{efficiency.total_lose_trade})</i>
                        </span>
                    </Item>
                    <Item label={<>Avg ROI for Each Trade<IconInfoAVGROI /></>}>
                        <b>{efficiency.roi_avg_all.toFixed(2) + '%'}</b>
                    </Item>
                    <Item label={<>Median Swing Time<IconInfoMedianSwing /></>}>
                        <b>{displayData(efficiency.swingTimeMedian?.toFixed(1))} days</b>
                    </Item>
                    <Item label={<>Active Buy Signal<IconInfoActiveBuySignal /></>}>
                        &nbsp;&nbsp;
                        <Space size="middle">
                            <span>{(efficiency.activeSignalBuyL1 ? <IconSignalBuyL1 /> : <IconSignalOff />)}L1</span>
                            <span>{(efficiency.activeSignalBuyL2 ? <IconSignalBuyL2 /> : <IconSignalOff />)}L2</span>
                        </Space>
                    </Item>
                    <Item label={<>Active Sell Signal<IconInfoActiveSellSignal /></>}>
                        &nbsp;&nbsp;
                        <Space size="middle">
                            <span>{(efficiency.activeSignalSellL1 ? <IconSignalSellL1 /> : <IconSignalOff />)}L1</span>
                            <span>{(efficiency.activeSignalSellL2 ? <IconSignalSellL2 /> : <IconSignalOff />)}L2</span>
                        </Space>
                    </Item>
                </Descriptions>
            </div>
        </Card>
    )
}

export default TickerInfo;