import '../styles/Demo.css';
import { PlusSquareOutlined, ProfileOutlined, FullscreenOutlined } from '@ant-design/icons';
import { IconInfoCandlestickChart, IconLoading, IconSignalBuyL1, IconSignalBuyL2, IconSignalSellL1, IconSignalSellL2, IconInHoldings } from '../components/Icons';
import { Layout, Tabs, Tour, Button, Tooltip, Row, Col, Space, Card, Modal, Image } from 'antd';
import React, { useEffect, useRef, useState, } from "react";
import { useNavigate } from "react-router-dom";
import TickerInfo from '../components/TickerInfo.jsx';
import CandlestickChart from '../components/CandlestickChart.jsx';
import { SignupButton } from "../components/buttons/signup-button";
import { formatPriceChg, getGainLossColor, getLogoUrl, isMobile, pourcentage } from '../utils/utils';
import { useSpring, animated } from 'react-spring';
import {
    tourStepDemoWelcome, tourStepWatchlist, tourStepAddToWatchlist, tourStepPortfolio, tourStepAssetDetails, tourStepSignalsAndModelEfficiency,
    tourStepCandlestickChart, tourStepFinancialCharts, tourStepHoldingsChart
} from '../components/tours/steps.jsx';

// Services
import { getTickerDemoInfos, getTickerDemoQuote } from "../services/demo.service";

// Tooltips
import { FullScreen, BuySignalDesc, SellSignalDesc } from '../components/TempLang';
import FinancialChart from '../components/FinancialChart.jsx';
import SankeyChart from '../components/SankeyChart.jsx';
import TickerLeftMenu from '../components/TickerLeftMenu.jsx';
import { Helmet } from 'react-helmet';
import PageTitle1 from '../components/PageTitle1.jsx';


// PARAMETERS //

const IS_MOBILE = isMobile()
const REAL_TIME_INTERVAL = 6000     // Each 6sec
const REAL_TIME_COUNT_OFF = 20      // Real time interval stop when counter reach x (no new quotes x times)


// SAHRED VARIABLES //

const { Content } = Layout;
const styleRow = { margin: '0 0 0 50px' }
const styleCol = { fontSize: '1.3em' }
const leftMenuItems = [
    {
        label: <>MA <IconInHoldings /></>,
        key: 'MA',
    },
    {
        label: <>TSLA <IconInHoldings /></>,
        key: 'TSLA',
    },
    {
        label: <>AAPL</>,
        key: 'AAPL',
    },
    {
        label: <>MSFT <IconInHoldings /></>,
        key: 'MSFT',
    },
    {
        label: <>NVDA <IconInHoldings /></>,
        key: 'NVDA',
    },
    {
        label: <>META</>,
        key: 'META',
    },
    {
        label: <>AMZN</>,
        key: 'AMZN',
    },
    {
        label: <>ABNB</>,
        key: 'ABNB',
    },
    {
        label: <>UBER</>,
        key: 'UBER',
    },
    {
        label: <>KO</>,
        key: 'KO',
    },
    {
        label: <>PEP</>,
        key: 'PEP',
    },
    {
        label: <>BRK-B</>,
        key: 'BRK-B',
    },
    {
        label: <>NKE</>,
        key: 'NKE',
    },
    {
        label: <>BTC-USD <IconInHoldings /></>,
        key: 'BTC-USD',
    },
    {
        label: <>ETH-USD</>,
        key: 'ETH-USD',
    },
    {
        label: <>XRP-USD</>,
        key: 'XRP-USD',
    }
]
const currTicker = 'MA'


const Demo = () => {

    const [priceChg, setPriceChg] = useState(null)
    const [tkrInfos, setTkrInfos] = useState(null)
    const [tickerInfoIsLoaded, setTickerInfoIsLoaded] = useState(false)
    const [tickerLeftMenuIsLoaded, setTickerLeftMenuIsLoaded] = useState(false)
    const navigate = useNavigate()
    const [candlesReloadKey, setCandlesReloadKey] = useState(0)
    const [candleInterval, setCandleInterval] = useState('daily')
    const [isModalVisible, setIsModalVisible] = useState(false);

    const toSignUp = () => { navigate('/newuserstartup') }

    // Price change animation
    const [springs, springApi] = useSpring(() => ({
        config: { duration: 800 },
        from: { opacity: 0.4 },
        to: {
            opacity: 1,
        },
    }))
    const animateChanges = () => {
        springApi.start({
            from: { opacity: 0.4 },
            to: {
                opacity: 1,
            },
        })
    }

    // Modal //

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    // TOUR //

    const tourRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
    const [tourOpen, setTourOpen] = useState(false)
    const endTour = () => {
        setTourOpen(false)
        window.scrollTo({ top: 0, behavior: "smooth" })
    }

    const steps = [
        {
            ...tourStepDemoWelcome(tkrInfos?.shortName, tkrInfos?.ticker),
            target: null,
            style: isMobile() && { width: '360px' },
        },
        {
            ...tourStepWatchlist,
            target: () => tourRefs[7].current,
            placement: 'right'
        },
        {
            ...tourStepAddToWatchlist,
            target: () => tourRefs[0].current,
        },
        {
            ...tourStepPortfolio,
            target: () => tourRefs[1].current,
        },
        {
            ...tourStepAssetDetails,
            target: () => tourRefs[2].current,
        },
        {
            ...tourStepSignalsAndModelEfficiency,
            target: () => tourRefs[3].current,
        },
        {
            ...tourStepCandlestickChart,
            target: () => tourRefs[4].current,
            placement: 'left',
            style: { width: '342px' }
        },
        {
            ...tourStepFinancialCharts,
            target: () => tourRefs[5].current,
            placement: 'left',
            style: { width: '342px' },
        },
        {
            ...tourStepHoldingsChart,
            target: () => tourRefs[6].current,
            placement: 'left',
            style: { width: '342px' }
        }
    ]

    const stepsMobile = [
        {
            ...tourStepDemoWelcome(tkrInfos?.shortName, tkrInfos?.ticker),
            target: null,
        },
        {
            ...tourStepAddToWatchlist,
            target: () => tourRefs[0].current,
        },
        {
            ...tourStepPortfolio,
            target: () => tourRefs[1].current,
        },
        {
            ...tourStepAssetDetails,
            target: () => tourRefs[2].current,
            style: { top: '30em' }
        },
        {
            ...tourStepSignalsAndModelEfficiency,
            target: () => tourRefs[3].current,
        },
        {
            ...tourStepCandlestickChart,
            target: () => tourRefs[4].current,
            style: { top: '92vh' }
        },
        {
            ...tourStepFinancialCharts,
            target: () => tourRefs[5].current
        },
        {
            ...tourStepHoldingsChart,
            target: () => tourRefs[6].current,
        }
    ]


    // CANDLESTICK CHART //

    const tabListIntervals = [
        {
            key: 'daily',
            label: '1d',
        },
        {
            key: 'hourly',
            label: '1h',
        },
    ]

    const CandleList = {
        daily: <CandlestickChart interval="1d" tkrInfos={tkrInfos} reloadKey={candlesReloadKey} isDemo={true} />,
        hourly: <CandlestickChart interval="1h" tkrInfos={tkrInfos} reloadKey={candlesReloadKey} isDemo={true} />,
    }

    const candleListModal = {
        daily: <CandlestickChart interval="1d" tkrInfos={tkrInfos} reloadKey={candlesReloadKey} height='80vh' modal={true} />,
        hourly: <CandlestickChart interval="1h" tkrInfos={tkrInfos} reloadKey={candlesReloadKey} height='80vh' modal={true} />,
    }

    // When tab change
    const onTabChange = async (key, event) => {
        setCandleInterval(key)
    }


    // PAGE CONTEXT //

    // MARK : Manage real time price update
    let countOff = 1
    let intervalId = null
    const updatePriceChg = async (tkr) => {
        const quotesResp = await getTickerDemoQuote(tkr)
        const lastQuote = quotesResp.data

        // If quote response is different than current, update last price and change
        if (lastQuote?.close && lastQuote?.prevClose) {
            const chg = pourcentage(lastQuote.close, lastQuote.prevClose)
            setPriceChg(prev => {
                if (prev != chg) {
                    countOff = 0
                    animateChanges()
                    return chg
                } else {
                    // No changes
                    return prev
                }
            })
        }

        // Stop real time if counter is reach > clear interval
        if (countOff >= REAL_TIME_COUNT_OFF)
            clearInterval(intervalId)
        else
            countOff++
    }


    // EFFECTS //

    useEffect(() => {

        const fetchData = async () => {
            const { data, error } = await getTickerDemoInfos(currTicker);
            setTkrInfos(data);

            const quotesResp = await getTickerDemoQuote(currTicker)
            const lastQuote = quotesResp.data

            setPriceChg(pourcentage(lastQuote.close, lastQuote.prevClose))
        }
        fetchData();

        if (intervalId == null) {
            intervalId = setInterval(updatePriceChg, REAL_TIME_INTERVAL, currTicker)

            // Clear interval on component unmount
            return () => clearInterval(intervalId)
        }
    }, [])

    useEffect(() => {
        if (tickerInfoIsLoaded) {
            setTourOpen(true)
        }
    }, [tickerInfoIsLoaded])


    // RETURN //

    // Loading not ready
    if (tkrInfos === null) {
        return (
            <Layout className="site-layout-content">
                <IconLoading />
            </Layout>
        )
    }

    return (
        <Layout className="site-layout-content">
            <Helmet>
                <title>Just Trade It: Demo</title>
            </Helmet>

            {/* Left menu */}
            {!isMobile() && (
                <TickerLeftMenu
                    setTickerLeftMenuIsLoaded={setTickerLeftMenuIsLoaded}
                    tourRef={tourRefs[7]}
                    items={leftMenuItems}
                    setItems={() => { }}
                    currItem={currTicker}
                    setCurrItem={toSignUp}
                    isDemo={true}
                    setCandlesReloadKey={setCandlesReloadKey} />
            )}

            <Content>
                <PageTitle1>
                    <Space align='baseline' size={0} style={{ float: 'right', }}>
                        <Tooltip placement='top' color='orange' title="Open portfolio">
                            <Button ref={tourRefs[1]} type="text" icon={<ProfileOutlined style={{ fontSize: '1.3em' }} />} href='/newuserstartup' />
                        </Tooltip>
                        <Tooltip placement='top' color='orange' title="Add to watchlist">
                            <Button ref={tourRefs[0]} type="text" icon={<PlusSquareOutlined style={{ fontSize: '1.3em' }} />} href='/newuserstartup' />
                        </Tooltip>
                    </Space>
                    <img className='content-title-logo' width={27} src={getLogoUrl(currTicker)} /> {tkrInfos.shortName?.replace(/\s+\(The\)$/i, '')} • <animated.span style={{ ...springs, fontSize: '0.7em', color: getGainLossColor(priceChg) }}>{formatPriceChg(priceChg)}</animated.span>
                </PageTitle1>

                <TickerInfo tkrInfos={tkrInfos} setTickerInfoIsLoaded={setTickerInfoIsLoaded} tourRefs={tourRefs} ticker={currTicker} addToWatchList={null} />

                <Card ref={tourRefs[4]} className='card-chart-candle card' style={{ marginTop: '2em' }}
                    tabBarExtraContent={
                        <Space size={IS_MOBILE ? 'small' : 'middle'}>
                            <Tooltip placement='top' color='orange' title={FullScreen}>
                                <Button type="text" size='small' style={{ color: 'grey' }} icon={<FullscreenOutlined />} onClick={showModal} />
                            </Tooltip>
                            <IconInfoCandlestickChart />
                        </Space>}
                    tabList={tabListIntervals}
                    activeTabKey={candleInterval}
                    onTabChange={onTabChange}
                    tabProps={{
                        size: 'middle',
                    }}>
                    {CandleList[candleInterval]}
                </Card>

                <div ref={tourRefs[5]}>
                    <FinancialChart ticker={currTicker} isDemo={true} />
                </div>
                <div ref={tourRefs[6]} style={{ marginTop: '2em' }}>
                    <SankeyChart ticker={currTicker} isDemo={true} />
                </div>

                <div style={{ margin: '120px 0 100px 0', textAlign: 'center' }}>
                    <SignupButton className="demo-signup" type="primary" label='Discover more AI features >' />
                </div>
            </Content>

            <Modal wrapClassName='modal-chart-candle' title={tkrInfos.longName} open={isModalVisible} onCancel={handleCancel} footer={null} width='95%' centered>
                {candleListModal[candleInterval]}
            </Modal>

            {!isMobile() ? (
                // TODO : Fix behavior: 'smooth'
                <Tour open={tourOpen} onClose={() => endTour()} steps={steps} scrollIntoViewOptions={{ behavior: 'instant', block: 'center', inline: 'center' }} />
            ) : (
                <Tour open={tourOpen} onClose={() => endTour()} steps={stepsMobile} scrollIntoViewOptions={{ behavior: 'instant', block: 'center', inline: 'center' }} arrow={false} />
            )}
        </Layout>
    )
}

export default Demo;