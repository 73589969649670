import { Layout, Typography, Table, Descriptions, Checkbox, Space, Select, Image, Card } from 'antd';
import { IconLoading, IconSignalBuyL1, IconSignalBuyL2, IconSignalSellL1, IconSignalSellL2, IconSignalOff, IconWarnShortRatio, IconInHoldings } from '../components/Icons';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { useAuth0 } from "@auth0/auth0-react";
import UserContext from '../components/UserContext';
import { getTickerFull } from "../services/ticker.service";
import { navigateToTickerObject, formatNumberToB, formatPourcent, arrayIntersection, getAITag, displayData, getLogoUrl } from '../utils/utils'
import Column from 'antd/es/table/Column';
import ColumnGroup from 'antd/es/table/ColumnGroup';
import { TagAIAverage, TagClassResilient, TagClassYoung, TagAIGood, TagAIHigh, TagAILow } from '../components/Tags';
import { getColumnSearchProps } from '../utils/CustomizeTable';
import MenuCollapse from '../components/MenuCollapse';
import { STORE_SCREENER_FILTER_BSL1, STORE_SCREENER_FILTER_BSL2, STORE_SCREENER_FILTER_EXCHANGE, STORE_SCREENER_FILTER_INDUSTRY, STORE_SCREENER_FILTER_SSL1, STORE_SCREENER_FILTER_SSL2, STORE_SCREENER_FILTER_TAG_AI_AVG, STORE_SCREENER_FILTER_TAG_AI_GOOD, STORE_SCREENER_FILTER_TAG_AI_HIGH, STORE_SCREENER_FILTER_TAG_AI_LOW, STORE_SCREENER_FILTER_TAG_RESILIENT } from '../JTIConst';
import PageTitle1 from '../components/PageTitle1';

const { Text } = Typography;
const { Item, } = Descriptions;


const Screener = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { userContext, doIHold } = useContext(UserContext);
    const [tableData, setTableData] = useState(null);
    const [filterIndustryOpts, setFilterIndustryOpts] = useState([]);
    const [filterExchangeOpts, setFilterExchangeOpts] = useState([]);
    const paginationOptions = {
        defaultPageSize: 50,
        showSizeChanger: true,
    }

    // Filter
    const [loading, setLoading] = useState(true)
    const [filterBSL1, setFilterBSL1] = useState(localStorage.getItem(STORE_SCREENER_FILTER_BSL1)?.match('true') ? true : false)
    const [filterBSL2, setFilterBSL2] = useState(localStorage.getItem(STORE_SCREENER_FILTER_BSL2)?.match('true') ? true : false)
    const [filterSSL1, setFilterSSL1] = useState(localStorage.getItem(STORE_SCREENER_FILTER_SSL1)?.match('true') ? true : false)
    const [filterSSL2, setFilterSSL2] = useState(localStorage.getItem(STORE_SCREENER_FILTER_SSL2)?.match('true') ? true : false)
    const [filterTagResilient, setFilterTagResilient] = useState(localStorage.getItem(STORE_SCREENER_FILTER_TAG_RESILIENT)?.match('true') ? true : false)
    const [filterTagAIHigh, setFilterTagAIHigh] = useState(localStorage.getItem(STORE_SCREENER_FILTER_TAG_AI_HIGH)?.match('true') ? true : false)
    const [filterTagAIGood, setFilterTagAIGood] = useState(localStorage.getItem(STORE_SCREENER_FILTER_TAG_AI_GOOD)?.match('true') ? true : false)
    const [filterTagAIAverage, setFilterTagAIAverage] = useState(localStorage.getItem(STORE_SCREENER_FILTER_TAG_AI_AVG)?.match('true') ? true : false)
    const [filterTagAILow, setFilterTagAILow] = useState(localStorage.getItem(STORE_SCREENER_FILTER_TAG_AI_LOW)?.match('true') ? true : false)
    const [filterIndustry, setFilterIndustry] = useState(() => {
        if (localStorage.getItem(STORE_SCREENER_FILTER_INDUSTRY) !== null)
            return JSON.parse(localStorage.getItem(STORE_SCREENER_FILTER_INDUSTRY))
        else
            return []
    })
    const [filterExchange, setFilterExchange] = useState(() => {
        if (localStorage.getItem(STORE_SCREENER_FILTER_EXCHANGE) !== null)
            return JSON.parse(localStorage.getItem(STORE_SCREENER_FILTER_EXCHANGE))
        else
            return []
    })

    const symbolSearchRef = useRef(null);

    const toggleFilterBSL1 = (e) => {
        setFilterBSL1(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_BSL1, e.target.checked)
    }
    const toggleFilterBSL2 = (e) => {
        setFilterBSL2(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_BSL2, e.target.checked)
    }
    const toggleFilterSSL1 = (e) => {
        setFilterSSL1(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_SSL1, e.target.checked)
    }
    const toggleFilterSSL2 = (e) => {
        setFilterSSL2(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_SSL2, e.target.checked)
    }
    const toggleFilterTagResilient = (e) => {
        setFilterTagResilient(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_TAG_RESILIENT, e.target.checked)
    }
    const toggleFilterTagAIHigh = (e) => {
        setFilterTagAIHigh(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_TAG_AI_HIGH, e.target.checked)
    }
    const toggleFilterTagAIGood = (e) => {
        setFilterTagAIGood(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_TAG_AI_GOOD, e.target.checked)
    }
    const toggleFilterTagAIAverage = (e) => {
        setFilterTagAIAverage(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_TAG_AI_AVG, e.target.checked)
    }
    const toggleFilterTagAILow = (e) => {
        setFilterTagAILow(e.target.checked)
        localStorage.setItem(STORE_SCREENER_FILTER_TAG_AI_LOW, e.target.checked)
    }
    const updIndustryFilter = (e) => {
        setFilterIndustry(e)
        localStorage.setItem(STORE_SCREENER_FILTER_INDUSTRY, JSON.stringify(e))
    }
    const updExchangeFilter = (e) => {
        setFilterExchange(e)
        localStorage.setItem(STORE_SCREENER_FILTER_EXCHANGE, JSON.stringify(e))
    }


    // EFFECTS //

    useEffect(() => {
        setLoading(true)

        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getTickerFull(accessToken)

            // Remove stocks indexes
            const tkrFullList = data.filter(item => !item.ticker.startsWith('^'))

            // Use a Set to store unique industries
            const uniqueIndustries = new Set();
            tkrFullList.forEach((item) => {
                uniqueIndustries.add(item.industry)
            })
            const optsIndustries = []
            uniqueIndustries.forEach((industry) => {
                optsIndustries.push({
                    label: industry,
                    value: industry
                })
            })
            setFilterIndustryOpts(optsIndustries)

            // Use a Set to store unique echanges
            const uniqueExchanges = new Set();
            tkrFullList.forEach((item) => {
                uniqueExchanges.add(item.exchange)
            })
            const optsExchanges = []
            uniqueExchanges.forEach((exchange) => {
                optsExchanges.push({
                    label: exchange,
                    value: exchange
                })
            })
            setFilterExchangeOpts(optsExchanges)

            // Manage filters
            var tkrFullListFiltered = tkrFullList
            let atLeastOneSignalFilter = false
            if (filterBSL1 || filterBSL2 || filterSSL1 || filterSSL2) {
                atLeastOneSignalFilter = true
                tkrFullListFiltered = []

                let listFilterBSL1 = []
                if (filterBSL1) {
                    listFilterBSL1 = tkrFullList.filter(item => item.oneDay?.activeSignalBuyL1 == 1)
                }
                let listFilterBSL2 = []
                if (filterBSL2) {
                    listFilterBSL2 = tkrFullList.filter(item => item.oneDay?.activeSignalBuyL2 == 1)
                }
                let listFilterSSL1 = []
                if (filterSSL1) {
                    listFilterSSL1 = tkrFullList.filter(item => item.oneDay?.activeSignalSellL1 == 1)
                }
                let listFilterSSL2 = []
                if (filterSSL2) {
                    listFilterSSL2 = tkrFullList.filter(item => item.oneDay?.activeSignalSellL2 == 1)
                }

                tkrFullListFiltered = tkrFullListFiltered.concat(listFilterBSL1, listFilterBSL2, listFilterSSL1, listFilterSSL2)
            }

            if (filterTagAIHigh || filterTagAIGood || filterTagAIAverage || filterTagAILow) {

                let listTagAIHigh = []
                if (filterTagAIHigh) {
                    listTagAIHigh = tkrFullListFiltered.filter(item => item.oneDay?.total_win_percent >= 78)
                }
                let listTagAIGood = []
                if (filterTagAIGood) {
                    listTagAIGood = tkrFullListFiltered.filter(item => item.oneDay?.total_win_percent >= 69 && item.oneDay?.total_win_percent < 78)
                }
                let listTagAIAverage = []
                if (filterTagAIAverage) {
                    listTagAIAverage = tkrFullListFiltered.filter(item => item.oneDay?.total_win_percent >= 60 && item.oneDay?.total_win_percent < 69)
                }
                let listTagAILow = []
                if (filterTagAILow) {
                    listTagAILow = tkrFullListFiltered.filter(item => item.oneDay?.total_win_percent < 60)
                }

                // Union on AI filters and intersect with signals filters or the full list
                const aiFiltersList = listTagAIHigh.concat(listTagAIGood, listTagAIAverage, listTagAILow)
                tkrFullListFiltered = arrayIntersection({ key: "ticker" }, tkrFullListFiltered, aiFiltersList)
            }

            if (filterTagResilient) {
                const listFilterTagResilient = tkrFullListFiltered.filter(item => item.tags?.resilient == true)
                tkrFullListFiltered = arrayIntersection({ key: "ticker" }, tkrFullListFiltered, listFilterTagResilient)
            }

            if (filterIndustry.length > 0) {
                tkrFullListFiltered = tkrFullListFiltered.filter(item => filterIndustry.includes(item.industry))
            }

            if (filterExchange.length > 0) {
                tkrFullListFiltered = tkrFullListFiltered.filter(item => filterExchange.includes(item.exchange))
            }

            const table = []
            for (const tkrInfos of tkrFullListFiltered) {

                // Avoid duplicates
                if (table.find((i) => i.key == tkrInfos.ticker) == undefined) {

                    // Define tags
                    const tagResilient = tkrInfos.tags?.resilient ? <TagClassResilient /> : ''
                    const tagYoung = tkrInfos.tags?.young ? <TagClassYoung /> : ''
                    const modelEfficiencyTag = getAITag(tkrInfos.oneDay?.total_win_percent)
                    const tagsNode = <Space size={[2, 5]} wrap>{modelEfficiencyTag}{tagResilient}{tagYoung}</Space>

                    table.push({
                        key: tkrInfos.ticker,
                        symbol: tkrInfos.ticker,
                        shortName: tkrInfos.shortName,
                        industry: tkrInfos.industry,
                        tags: tagsNode,
                        marketCap: tkrInfos.marketCap,
                        dividendYield: tkrInfos.dividendYield,
                        exDividendDate: tkrInfos.exDividendDate,
                        shortRatio: tkrInfos.shortRatio,
                        totalWinPerct: tkrInfos.oneDay?.total_win_percent,
                        roiAVGAll: tkrInfos.oneDay?.roi_avg_all,
                        activeSignalBuyL1: tkrInfos.oneDay?.activeSignalBuyL1,
                        activeSignalBuyL2: tkrInfos.oneDay?.activeSignalBuyL2,
                        activeSignalSellL1: tkrInfos.oneDay?.activeSignalSellL1,
                        activeSignalSellL2: tkrInfos.oneDay?.activeSignalSellL2,
                    })
                }
            }

            setTableData(table)
            setLoading(false)
        }
        fetchData()
    }, [userContext, filterBSL1, filterBSL2, filterSSL1, filterSSL2, filterTagAIHigh, filterTagAIGood, filterTagAIAverage, filterTagAILow, filterTagResilient, filterIndustry, filterExchange])

    // userContext needs to be setted otherwise error
    if (userContext == null || (userContext.watchlist.length > 0 && tableData == null)) {
        return (
            <Layout className="site-layout-content">
                <IconLoading />
            </Layout>)
    }

    const tagCheckboxStyle = { marginRight: '0.4em' }
    const menuItem = [{
        label: <div className="menu-title">FILTERS</div>,
        children:
            <Descriptions column={{ xs: 1, sm: 1, md: 4, lg: 4, xl: 4, xxl: 4 }} labelStyle={{ color: 'black', fontWeight: '430' }}>
                <Item label='• Buy Signals'>
                    <Space>
                        <Checkbox id='filterBSL1' checked={filterBSL1} onChange={toggleFilterBSL1} /> L1 <IconSignalBuyL1 />
                        <Checkbox id='filterBSL2' checked={filterBSL2} onChange={toggleFilterBSL2} /> L2 <IconSignalBuyL2 />
                    </Space>
                </Item>
                <Item label='• Sell Signals'>
                    <Space>
                        <Checkbox id='filterSSL1' checked={filterSSL1} onChange={toggleFilterSSL1} /> L1 <IconSignalSellL1 />
                        <Checkbox id='filterSSL2' checked={filterSSL2} onChange={toggleFilterSSL2} /> L2 <IconSignalSellL2 />
                    </Space>
                </Item>
                <Item label='• AI Tags' span={{ md: 2 }}>
                    <Space size='small' wrap>
                        <div>
                            <Checkbox id='filterWinRateHigh' checked={filterTagAIHigh} onChange={toggleFilterTagAIHigh} style={tagCheckboxStyle} /><TagAIHigh />
                        </div>
                        <div>
                            <Checkbox id='filterWinRateGood' checked={filterTagAIGood} onChange={toggleFilterTagAIGood} style={tagCheckboxStyle} /><TagAIGood />
                        </div>
                        <div>
                            <Checkbox id='filterWinRateAvg' checked={filterTagAIAverage} onChange={toggleFilterTagAIAverage} style={tagCheckboxStyle} /><TagAIAverage />
                        </div>
                        <div>
                            <Checkbox id='filterWinRateLow' checked={filterTagAILow} onChange={toggleFilterTagAILow} style={tagCheckboxStyle} /><TagAILow />
                        </div>
                        <div>
                            <Checkbox id='filterResilient' checked={filterTagResilient} onChange={toggleFilterTagResilient} style={tagCheckboxStyle} /><TagClassResilient />
                        </div>
                    </Space>
                </Item>
                <Item label='• Industry' labelStyle={{ padding: '0.3em 0 0 0' }} span={{ md: 2 }}>
                    <Select style={{ width: '90%' }} placeholder="Select industries"
                        mode="multiple"
                        allowClear
                        defaultValue={filterIndustry}
                        onChange={updIndustryFilter}
                        options={filterIndustryOpts}
                    />
                </Item>
                <Item label='• Exchange' labelStyle={{ padding: '0.3em 0 0 0' }} span={{ md: 2 }}>
                    <Select style={{ width: '90%' }} placeholder="Select exchanges"
                        mode="multiple"
                        allowClear
                        defaultValue={filterExchange}
                        onChange={updExchangeFilter}
                        options={filterExchangeOpts}
                    />
                </Item>
            </Descriptions>
    }]

    return (
        <Layout className="site-layout-content">

            <PageTitle1> • AI Screener</PageTitle1>

            {/* FILTERS */}
            <MenuCollapse items={menuItem} enable={false} />

            {loading ?
                <IconLoading />
                :
                <>
                    {/* TABLE */}
                    <Card id='screenerTable' className='card'>
                        <Table className='table' size='small' dataSource={tableData} pagination={paginationOptions} rowClassName={'table-row-pointer'}
                            onRow={(record, rowIndex) => navigateToTickerObject(record.key)}>
                            <Column title="Symbol" dataIndex="symbol" key="symbol" {...getColumnSearchProps(symbolSearchRef, 'symbol')}
                                render={(_, record) => (
                                    <Space align="center">
                                        <Image src={getLogoUrl(record.symbol)} alt={record.symbol} width={16} preview={false} />
                                        <Text>{record.symbol} {doIHold(record.symbol) && <IconInHoldings />}</Text>
                                    </Space>)} />

                            <Column title="Name" dataIndex="shortName" key="shortName" responsive={['md']} sorter={(a, b) => a.shortName.localeCompare(b.shortName)} />
                            <Column title="Industry" dataIndex="industry" key="industry" responsive={['md']} sorter={(a, b) => a.industry.localeCompare(b.industry)} />
                            <Column title={<><span className='ai-logo'>AI</span> Tags</>} align='left' dataIndex="tags" key="tags" width='17%' responsive={['sm']} 
                                sorter={(a, b) => renderToString(a.tags).localeCompare(renderToString(b.tags))} sortDirections={['descend', 'ascend']} />
                            <Column title="Market Cap" align='center' dataIndex="marketCap" key="marketCap" responsive={['xl']}
                                sorter={(a, b) => a.marketCap - b.marketCap}
                                render={(_, record) => formatNumberToB(record.marketCap)} />
                            <Column title="Div. Yield" align='center' dataIndex="dividendYield" key="dividendYield" width='5%' responsive={['sm']}
                                sorter={(a, b) => a.dividendYield - b.dividendYield}
                                render={(_, record) =>
                                    displayData(record.dividendYield) === '-' ? '-' :
                                        formatPourcent(record.dividendYield)} />
                            <Column title="Ex-Div. Date" align='center' dataIndex="exDividendDate" key="exDividendDate" width='8%' responsive={['sm']}
                                render={(_, record) => displayData(record.exDividendDate)} />
                            <Column title="Short Ratio" align='center' dataIndex="shortRatio" key="shortRatio" responsive={['sm']}
                                sorter={(a, b) => a.shortRatio - b.shortRatio}
                                render={(_, record) =>
                                    displayData(record.shortRatio) === '-' ? '-' :
                                        record.shortRatio < 4 ? record.shortRatio + '%' : (<>{record.shortRatio + '%'}<IconWarnShortRatio /></>)} />

                            <Column title="% Win" align='center' dataIndex="totalWinPerct" key="totalWinPerct" responsive={['sm']}
                                sorter={(a, b) => a.totalWinPerct - b.totalWinPerct} sortDirections={['descend', 'ascend']}
                                render={(_, record) => record.totalWinPerct?.toFixed(2) + '%'} />
                            <Column title="AVG ROI" align='center' dataIndex="roiAVGAll" key="roiAVGAll" responsive={['sm']}
                                sorter={(a, b) => a.roiAVGAll - b.roiAVGAll} sortDirections={['descend', 'ascend']}
                                render={(_, record) => record.roiAVGAll?.toFixed(2) + '%'} />

                            <ColumnGroup title='Active Buy Signal'>
                                <Column title={<><IconSignalBuyL1 /> L1</>} align='center' dataIndex="activeSignalBuyL1" key="activeSignalBuyL1"
                                    render={(_, record) => record.activeSignalBuyL1 ? <IconSignalBuyL1 /> : <IconSignalOff />}
                                    sorter={(a, b) => a.activeSignalBuyL1 - b.activeSignalBuyL1} sortDirections={['descend', 'ascend']}
                                />
                                <Column title={<><IconSignalBuyL2 /> L2</>} align='center' dataIndex="activeSignalBuyL2" key="activeSignalBuyL2"
                                    sorter={(a, b) => a.activeSignalBuyL2 - b.activeSignalBuyL2} sortDirections={['descend', 'ascend']}
                                    render={(_, record) => record.activeSignalBuyL2 ? <IconSignalBuyL2 /> : <IconSignalOff />} />
                            </ColumnGroup>

                            <ColumnGroup title='Active Sell Signal'>
                                <Column title={<><IconSignalSellL1 /> L1</>} align='center' dataIndex="activeSignalSellL1" key="activeSignalSellL1"
                                    render={(_, record) => record.activeSignalSellL1 ? <IconSignalSellL1 /> : <IconSignalOff />}
                                    sorter={(a, b) => a.activeSignalSellL1 - b.activeSignalSellL1} sortDirections={['descend', 'ascend']}
                                />
                                <Column title={<><IconSignalSellL2 /> L2</>} align='center' dataIndex="activeSignalSellL2" key="activeSignalSellL2"
                                    render={(_, record) => record.activeSignalSellL2 ? <IconSignalSellL2 /> : <IconSignalOff />}
                                    sorter={(a, b) => a.activeSignalSellL2 - b.activeSignalSellL2} sortDirections={['descend', 'ascend']}
                                />
                            </ColumnGroup>
                        </Table>
                    </Card>
                    <div className='legend-box'>
                        <Text className='legend-text' italic>The AI Model data is for the daily chart.</Text>
                    </div>
                </>}
        </Layout>
    )
}

export default Screener